import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { BuilderComponent } from "@builder.io/react"
// import '@builder.io/widgets/dist/lib/builder-widgets-async';
import Layout from "../components/layout"
import SEO from "../components/seo"
import marselOg from "../images/marsel-og.png"

const Marsel = props => {
  const content = props.data?.allBuilderModels.onePage?.content
  const ogImageUrl = props.data?.site.siteMetadata.siteURL + marselOg
  return (
    <Layout data={{ headerType: "light" }}>
      <SEO
        title="Product Recommendations for Shopify"
        description="Maximize sales and AOV with AI-driven product recommendations."
        image={{ src: ogImageUrl }}
      />
      <BuilderComponent content={content} model="page" />
    </Layout>
  )
}

export default Marsel

export const pageQuery = graphql`
  query($path: String!) {
    allBuilderModels {
      onePage(target: { urlPath: $path }, options: { cachebust: true }) {
        content
      }
    }
    site {
      siteMetadata {
        siteURL
      }
    }
  }
`
